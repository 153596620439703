<template>
    <div class="full-height pa-20">
        <h6>{{ program.name }}</h6>
        <div class="flex-row">
            <div
                class="pa-10 bg-white width-80"
            >
                <table class="table td-left">
                    <col width="120px">
                    <tbody>
                    <tr>
                        <th>구분</th>
                        <td>
                            <button
                                v-for="(branch, index) in viewGroup"
                                :key="'branch_' + index"
                                :value="branch.code"
                                class="pa-5-10 "
                                :class="item.viewGroup == branch.code ? 'bg-identify' : 'bg-identify-outline'"
                                @click="setViewGroup(branch.code)"
                            >{{ branch.name }}
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <th>제목</th>
                        <td><input v-model="item.title" class="input-box pa-10" placeholder="제목을 입력하세요" maxlength="50"/>
                        </td>
                    </tr>
                    <tr>
                        <th>내용</th>
                        <td>
                            <editor
                                v-model="item.content"
                                height="350px"
                                initialEditType="markdown"
                                ref="content"
                                class="text-left "
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>예약 등록</th>
                        <td>
                            <button
                                class="pa-5-10 "
                                :class="!item.isReserved ? 'bg-identify' : 'bg-identify-outline'"
                                @click="item.isReserved = false"
                            >즉시 공지
                            </button>
                            <button
                                class="pa-5-10 mr-10"
                                :class="item.isReserved ? 'bg-identify' : 'bg-identify-outline'"
                                @click="item.isReserved = true"
                            >예약 공지
                            </button>

                            <template
                                v-if="item.isReserved"
                            >
								<span class="position-relative">
									<input v-model="item.date" class="input-box-inline text-center  mr-10"
                                           placeholder="예약일" readonly @click="is_data_pick = !is_data_pick"/>
									<v-date-picker
                                        v-if="is_data_pick"
                                        v-model="item.date"
                                        no-title
                                        scrollable
                                        class="position-absolute"
                                        style="top: 35px; right: 70px"
                                        @change="is_data_pick = false"
                                    ></v-date-picker>
								</span>
                                <select
                                    v-model="item.hour"
                                    class="input-box-inline  "
                                >
                                    <option value="">시간</option>
                                    <option
                                        v-for="time in 24"
                                        :key="'time_' + time"
                                        :value="time - 1"
                                    >{{ ('0' + (time - 1)).slice(-2) }}
                                    </option>
                                </select> :

                                <select
                                    v-model="item.min"
                                    class="input-box-inline "
                                >
                                    <option value="">분</option>
                                    <option value="0">00</option>
                                    <option value="30">30</option>
                                </select>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <th>상단 고정</th>
                        <td>
                            <button
                                class="pa-5-10 "
                                :class="item.fix ? 'bg-identify' : 'bg-identify-outline'"
                                @click="item.fix = true"
                            >고정
                            </button>
                            <button
                                class="pa-5-10 mr-10"
                                :class="!item.fix ? 'bg-identify' : 'bg-identify-outline'"
                                @click="item.fix = false"
                            >미고정
                            </button>

                            <label>
                                상단 고정 선택 시 최근 등록 순으로 정렬됩니다.
                            </label>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div
                    class="mt-30 justify-center"
                >
                    <button
                        class="bg-identify pa-10-20 mr-10"
                        @click="save"
                    >확인
                    </button>
                    <button
                        class="bg-ddd pa-10-20"
                        @click="isCancel"
                    >목록
                    </button>
                </div>
            </div>
            <ul class="ml-10 pa-10 bg-white width-20" v-if="item.viewGroup === 'SELECT'">
                <li class="box mb-5 pa-5-10" v-for="item in selectedIds" :key="item.idx">
                    <p class="mr-5">{{ item.name }}</p>
                </li>
            </ul>
        </div>
        <Modal
            :is_modal="is_select"
            :option="modal_option"
            :top="true"
            :bottom="false"

            title="선택"
            width="80%"
            height="80%"

            @close="clear"
        >
            <AgencySearchList
                slot="modal-content"

                :item_type="item.type"
                :user="user"
                :selected="selectedIds"

                @click="setSelectedIds"
                @cancel="clear"
            ></AgencySearchList>
        </Modal>
        <Modal
            :is_modal="is_modal"
            :option="modal_option"
            :top="true"
            :bottom="true"

            title="등록 취소"
            content="내용을 저장하지 않고 목록으로 돌아가시겠습니까?"
            width="400px"
            content_class="ptb-30"

            @close="clear"
            @click="toList"
            @cancel="clear"
        ></Modal>
    </div>
</template>

<script>

import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor} from '@toast-ui/vue-editor';
import Modal from "@/components/Modal";
import AgencySearchList from "@/view/Board/AgencySearchList.vue";

export default {
    name: 'NoticeItem'
    , components: {AgencySearchList, Modal, Editor}
    , data: function () {
        return {
            user: [],
            program: {
                name: '공지사항 등록'
                , top: true
                , title: true
                , bottom: false
            }
            , item: {
                type: 99,
                hour: '0'
                , min: '0'
                , isReserved: false
                , fix: false
                , viewGroup: 'ALL'
                , date: this.date.getToday('-')
            }
            , is_data_pick: false
            , items_branch: []
            , is_select: false
            , is_modal: false
            , modal_option: {}
            , viewGroup: [
                {name: '전체', code: 'ALL'},
                {name: '총판 전체', code: 'BRANCH'},
                {name: '지사 전체', code: 'DISTRIBUTOR'},
                {name: '대리점 전체', code: 'AGENCY'},
                {name: '딜러 전체', code: 'RESELLER'},
                {name: '가맹점 전체', code: 'SHOP'},
                {name: '선택', code: 'SELECT'},
            ]
            , selectedIds: []
        }
    }
    , methods: {
        save: async function () {
            try {
                this.$layout.onLoading()

                let content = this.$refs.content.invoke("getMarkdown")
                this.item.content = content

                console.log(this.item, 'item')
                if(this.item.isReserved) {
                    this.$set(this.item, 'reservedTime', this.item.date.replaceAll('-', '') + ('0' + this.item.hour).slice(-2) + ('0' + this.item.min).slice(-2))
                }
                if(this.item.viewGroup === 'SELECT') {
                        this.item.selectedIds = []
                    this.selectedIds.forEach((item) => {
                        this.item.selectedIds.push(item.idx)
                    })
                }
                console.log(this.item, 'asdasdasd')
                let result = await this.$Axios({
                    method: 'post'
                    , url: 'notice'
                    , data: this.item
                })
                if (result.success) {
                    this.$layout.setNotify( {type: 'success', message: result.message})
                    this.$router.back()
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                    this.$layout.setNotify( {type: 'error', message: result.error[0].message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , setViewGroup: function (code) {
            this.item.viewGroup = code
            if(code === 'SELECT') {
                this.is_select = true
            } else {
                this.selectedIds = []
            }
        }
        , setSelectedIds: function (selectId) {
            this.selectedIds = selectId
            console.log(this.selectedIds, 'selected id')
            this.clear()
        }
        , isCancel: function () {
            this.is_modal = true
        }
        , close: function () {
            this.is_modal = false
            this.is_select = false
        }
        , clear: function () {
            this.is_modal = false
            this.is_select = false
        }
        , toList: function () {
            this.$router.back()
        }
    }
    , created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
    }
}
</script>

<style>
.toastui-editor-defaultUI-toolbar button {
    margin: 7px 0px;
}
</style>
