<template>
    <div class="full-height flex-column ">
        <ul
            v-if="Object.keys(items).length > 0"
            class="justify-space-between full-height box overflow-y-auto"
        >
            <template
                v-for="(item, index) in item_list"
            >
                <li
                    v-if="(item_type < 99 && item_type > index + 1) || (item_type == 99 && index < 5)"
                    :key="'type_' + index"
                    class="flex-1 border-right full-height flex-column"
                >
                    <div class="bg-eee flex-row justify-center">
                        <input type="checkbox" :id="item.type" v-model="selectedAll" :value="item.type" @change="setItem(item)">
                        <label class="ml-5 h6" :for="item.type">
                            {{ item.typeName }}
                        </label>
                    </div>
                    <ul
                        v-if="item.list.length > 0"
                        class="pa-10 full-height  overflow-y-auto "
                    >
                        <li
                            v-for="(sub, sub_index) in item.list"
                            :key="'type_' + index + '_sub_' + sub_index"

                            class="box pa-10 radius-10 mt-10  justify-space-between cursor-pointer"
                            :class="{'bg-identify-outline' : item_next[item.type] == sub }"

                            @click="next(item.type, sub) "
                        >
                            <div>
                                <input type="checkbox" :id="'chk_' + sub.idx" v-model="selectedIds" :value="sub" @change="itemSort">
                                    <v-icon
                                        v-if="false"
                                        :class="{'color-identify' : item_select.idx == sub.idx}"
                                    >mdi mdi-checkbox-marked
                                    </v-icon>
                                <label :for="'chk_' + sub.idx">
                                    {{ sub.name }}
                                </label>
                            </div>
                            <v-icon
                                v-if="item.type !== 'shopList'"
                                @click="next(item.type, sub)"
                                :class="{'color-identify' : item_next[item.type] == sub }"
                            >mdi mdi-chevron-right
                            </v-icon>
                        </li>
                    </ul>
                    <div
                        v-else
                        class="full-height flex-column justify-center"
                    >
                        <div class="text-center">
                            <v-icon
                                class="size-px-48 color-icon"
                            >mdi mdi-cloud-off-outline
                            </v-icon>
                        </div>
                    </div>
                </li>
            </template>
        </ul>

        <div
            v-else
            class="full-height flex-column justify-center"
        >
            <div class="text-center">
                <v-icon
                    class="size-px-48 color-icon"
                >mdi mdi-cloud-off-outline
                </v-icon>
                <br/>
                <br/>
                <div class="font-weight-bold size-px-24">No Data</div>
            </div>
        </div>
        <div class="box full-width mt-10 bg-eee overflow-y-auto" style="height: 10rem;">
            <ul class="flex-row-wrap pa-10">
                <li class="box bg-white mr-5 mb-5 pa-5-10" v-for="item in selectedIds" :key="item.idx">
                    <div class="flex-row justify-center items-center">
                        <p class="mr-5">{{ item.name }}</p>
                        <button
                            class="modal-btn-close"
                            @click="deleteId(item.idx)"
                        ><v-icon >mdi-close-circle</v-icon></button>
                    </div>
                </li>
            </ul>
        </div>
        <div class="mt-10">
            <button
                class="bg-identify pa-10-20 mr-10"
                @click="save"
            >확인
            </button>
            <button
                class="btn-default pa-10-20"
                @click="$emit('cancel')"
            >취소
            </button>
        </div>
    </div>
</template>

<script>
import {Axios} from "@/resources/axios/axios";

export default {
    name: 'AgencySearchList'
    , props: ['item_type', 'user', 'selected']
    , components: {}
    , data: function () {
        return {
            Axios: Axios
            , item_select: {}
            , item_next: {
                branchList: ''
                , distributorList: ''
                , agencyList: ''
                , resellerList: ''
                , shopList: ''
            }
            , items: {
                branchList: []
                , distributorList: []
                , agencyList: []
                , resellerList: []
                , shopList: []
            }
            , upperIdx: ''
            , selectedIds: []
            , selectedAll : []
        }
    }
    , computed: {
        item_list: function () {
            let list = []
            let typeName = ''
            for (let [key, value] of Object.entries(this.items)) {
                if (key === 'branchList') {
                    typeName = '총판'
                } else if (key === 'distributorList') {
                    typeName = '지사'
                } else if (key === 'agencyList') {
                    typeName = '대리점'
                } else if (key === 'resellerList') {
                    typeName = '딜러'
                } else if (key === 'shopList') {
                    typeName = '가맹점'
                }
                list.push({
                    type: key
                    , typeName: typeName
                    , list: value
                })
            }
            console.log(list, 'agency list')
            return list
        }
    }
    , methods: {
        itemSort: function () {
            this.selectedIds.sort((a, b) => {
                if (a.type > b.type) return 1;
                if (a.type < b.type) return -1;
                return 0;
            });
        },
        setItem: function (item) {
            console.log(this.selectedAll)
            let list = []
            let unique = []
            if(this.selectedAll.indexOf(item.type) !== -1){
                list = item.list.concat(this.selectedIds)
                unique = list.filter((item, index) =>
                    index === list.findIndex((sub) => sub.idx === item.idx));
                this.selectedIds = unique
            } else {
                this.selectedIds = this.selectedIds.filter(x => !item.list.includes(x))
            }
            this.itemSort()
        },
        deleteId: function (id) {
            for(let i = 0; i < this.selectedIds.length; i++) {
                if (this.selectedIds[i].idx === id) {
                    this.selectedIds.splice(i, 1);
                }
            }
        }
        , save: function () {
            this.$emit('click', this.selectedIds)
        }
        , next: function (type, sub) {
            console.log(type, sub)
            this.item_select = sub
            this.item_next[type] = sub
            this.upperIdx = sub.idx
            this.selectedAll = []
            if(type !== 'shopList') {
                this.getData(type)
            }
        }
        , getData: async function (type) {

            try {
                //this.$layout.onLoading()
                let result = await this.$Axios({
                    method: 'get'
                    , url: 'common/upper'
                    , data: {
                        upperIdx: this.upperIdx
                    }
                })
                if (result.success) {
                    this.next_list = result.data
                    if (type == 'branchList') {
                        this.items.distributorList = this.next_list.distributorList
                        this.items.agencyList = this.next_list.agencyList
                        this.items.resellerList = this.next_list.resellerList
                        this.items.shopList = this.next_list.shopList
                    } else if (type == 'distributorList') {
                        this.items.agencyList = this.next_list.agencyList
                        this.items.resellerList = this.next_list.resellerList
                        this.items.shopList = this.next_list.shopList
                    } else if (type == 'agencyList') {
                        this.items.resellerList = this.next_list.resellerList
                        this.items.shopList = this.next_list.shopList
                    } else if (type == 'resellerList') {
                        this.items.shopList = this.next_list.shopList
                    } else {
                        this.items = this.next_list
                    }
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                //this.$layout.offLoading()
            }
        }
    }
    , created() {
        this.$layout = this.$root.$children[0].$children[0]
        if (this.user.roleCode > 0) {
            this.items.branchList = [{
                idx: this.user.branchIdx
                , name: this.user.branchName
            }]
        }
        if (this.user.roleCode > 1) {
            this.items.distributorList = [{
                idx: this.user.distributorIdx
                , name: this.user.distributorName
            }]
        }
        if (this.user.roleCode > 2) {
            this.items.agencyList = [{
                idx: this.user.agencyIdx
                , name: this.user.agencyName
            }]
        }

        if (this.user.roleCode > 3) {
            this.items.resellerList = [{
                idx: this.user.resellerIdx
                , name: this.user.resellerName
            }]
        }

        this.getData(this.user.roleId + 'List')
        this.selectedIds = this.selected
    }
}

</script>